:root {
    font-size: 16px;
    font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --text-header: #141418;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --brand-orange: #ff7500;
    --position-blank: #333333;
    --position-gk: #ffcc00;
    --position-def: #cc3300;
    --position-mid: #00cc33;
    --position-for: #0066cc;
}

body {
    color: black;
    background-color: white;
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track {
    background-color: #1e1e24;
}

body::-webkit-scrollbar-thumb {
    background-color: var(--brand-orange);
}

main {
    margin-left: 5rem;
    padding: 1rem;
}

.home-widget {
    margin-bottom: 2rem;
}

/* Page and sub headers */

.page-header {
    margin-bottom: 1rem;
}

.page-header h1 {
    margin: 0.2rem 0;
    font-size: larger;
    font-weight: normal;
}

.page-header h1 .page-header-gameweek {
    padding-left: 0.5rem;
    font-size: medium;
    color: var(--brand-orange);
}

.page-header svg {
    margin-right: 0.5em;
}

.page-header-team-button {
    position: absolute;
    right: 0;
    top: 1rem;
}

.page-header-team-button svg {
    margin: 0;
}

.sub-header {
    font-size: medium;
    font-weight: normal;
}

.sub-header-gameweek {
    padding-left: 0.5rem;
    font-size: small;
    color: var(--brand-orange);
}

/* End of page headers */

/* MenuBar component */
/* navbar is the nav element */

.navbar {
    position: fixed;
    background-color: var(--bg-primary);
    transition: width 200ms ease;
}

/* navbar-nav is the ul */

.navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

/* nav-item is each li */

.nav-item {
    width: 100%;
}

.nav-item:first-child {
    background-color: var(--brand-orange);
}

.nav-item:first-child > .nav-link {
    background-color: var(--brand-orange);
    color: var(--text-header);
    font-weight: bolder;
}

.nav-item:first-child > .nav-link:hover {
    background-color: var(--brand-orange);
}

.nav-item:last-child {
    margin-top: auto;
}

/* nav-link is each Link react router component that produces an anchor tag */

.nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    color: var(--text-primary);
    text-decoration: none;
}

.nav-link:not(:first) {
    filter: grayscale(100%) opacity(0.7);
}

.nav-link:first {
    color: black;
}

.nav-link:hover {
    filter: grayscale(0%) opacity(1);
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
}

/* link-text is each span holding the link text */

.link-text {
    display: none;
    margin-left: 1rem;
}

/* the svg's used for link icons */

.nav-link svg {
    min-width: 2rem;
    margin: 0 1.5rem;
}

.nav-link svg path {
    fill: var(--text-primary);
}

.nav-link:hover > svg path {
    fill: var(--text-secondary);
}

.nav-link svg.lineup-set path {
    fill: #00cc33;
}

.nav-link svg.lineup-missing path {
    fill: red;
}

.nav-link:hover > svg.lineup-set path {
    fill: #00ff00;
}

.nav-link:hover > svg.lineup-missing path {
    fill: #ff3333;
}

.nav-button {
    background-color: var(--bg-primary);
    color: var(--text-primary);
}

/* misc */

.nav-logo {
    width: 5rem;
}

/* End of MenuBar component */

/* Table component */

table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 2rem;
}

/* rows */

thead tr, tbody tr {
    border-bottom: thin solid var(--text-primary);
}

thead tr, tbody tr:last-of-type {
    border-bottom-color: var(--brand-orange);
}

.table-gameweek tbody tr:nth-last-of-type(-n+2), .table-players tbody tr:nth-last-of-type(-n+2) {
    border-bottom-color: var(--brand-orange);
}

/* cells */

th, td {
    padding: 0.5rem;
}

th {
    text-align: left;
}

/* Inner tables */

tr.cup-knockout-fixtures-row td {
    padding-top: 0;
    padding-bottom: 0;
}

table.cup-knockout-fixtures {
    margin: 0;
}

table.cup-knockout-fixtures td {
    padding: 0.5rem;
    font-size: x-small;
}

table.cup-knockout-fixtures tr:last-of-type {
    border: none;
}

/* End of table component */

/* Player position component */

.position-player__blank {
    color: var(--position-blank);
}

.position-player__gk {
    color: var(--position-gk);
}

.position-player__def {
    color: var(--position-def);
}

.position-player__mid {
    color: var(--position-mid);
}

.position-player__for {
    color: var(--position-for);
}

/* End of player position component */

/* Forms, such as login form */

form {
    max-width: 20rem;
}

.form-item {
    width: 100%;
    margin: 0 0 1rem 0;
}

.form-item label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: small;
}

.form-item input, button {
    padding: 0;
    height: 2rem;
    border: solid thin;
    border-color: var(--brand-orange);
}

button {
    border: none;
    background-color: var(--brand-orange);
    padding: 0 0.5rem;
}

button svg {
    margin-right: 0.5rem;
}

.login-errors {
    font-size: small;
}

.login-errors ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: red;
}

/* End of forms */

/* Link components */

.team-link, .manager-link {
    margin: 1rem 0;
    font-size: small;
    text-decoration: underline;
    text-decoration-color: var(--brand-orange);
}

.team-link svg, .manager-link svg {
    margin-left: 0.5rem;
}

.gameweek-nav {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: -1rem;
    text-align: right;
}

.gameweek-nav-buttons button {
    margin-right: 1rem;
    height: 1.2rem;
}

.gameweek-nav-buttons button svg {
    margin-right: 0;
}

.team-lineup {
    font-size: small;
}

.team-lineup button, .cup-fixtures button {
    float: right;
    margin-right: 0.5rem;
}

.cup-fixtures button {
    margin-top: -3rem;
}

/* End of link components */

/* Player news */

.player-news-cell {
    text-align: center;
}

.player-news-chance-0-icon path {
    fill: darkred;
}

.player-news-chance-25-icon path {
    fill: red;
}

.player-news-chance-50-icon path {
    fill: darkorange
}

.player-news-chance-75-icon path {
    fill: orange
}

/* End of player news */

/* Player selected in lineup */

.player-selected {
    background-attachment: fixed;
}

.player-selected--1 {
    background-image: linear-gradient(to right, white, var(--position-blank));
}

.player-selected-1 {
    background-image: linear-gradient(to right, white, var(--position-gk));
}

.player-selected-2 {
    background-image: linear-gradient(to right, white, var(--position-def));
}

.player-selected-3 {
    background-image: linear-gradient(to right, white, var(--position-mid));
}

.player-selected-4 {
    background-image: linear-gradient(to right, white, var(--position-for));
}

/* End of player selected in lineup */

/* Player search form */

form#player-search {
    font-size: 80%;
    display: flex;
    width: 100%;
    margin-bottom: 1em;
}

form#player-search label {
    display: inline-block;
    margin-right: 2em;
}

/*  End of player search form*/

/* Trophies component */

.winner-trophy path {
    fill: goldenrod;
}

.runnerup-trophy path {
    fill: silver;
}

.third-trophy path {
    fill: chocolate;
}

.loser-trophy path {
    fill: #3c302c;
}

/* End of trophies component */

/* Waivers components */

.waiver-up-icon path {
    fill: #00ff00;
}

.waiver-down-icon path {
    fill: #ff0000;
}

.waiver-record {
    font-size: 70%;
}

.waiver-processed {
    background-image: linear-gradient(to right, white, #aaffaa);
}

.waiver-rejected {
    background-image: linear-gradient(to right, white, #ffaaaa);
}

/* End of waivers components */

/* Deadlines component */

.deadline-date {
    font-size: 80%;
}

/* End of deadlines component */

/* Cup eliminator things */

.cup-eliminator-icon {
    color: var(--brand-orange);
}

/* End of cup eliminator things */

/* Small screen */
@media only screen and (max-width: 600px) {
    main {
        margin: 0;
        padding-bottom: 5rem;
    }
    
    .navbar {
        bottom: 0;
        width: 100vw;
        height: 5rem;
    }

    .navbar-nav {
        flex-direction: row;
    }

    .nav-link {
        justify-content: center;
    }

    #table-nav-item {
        display: none;
    }
}

/* Large screen */
@media only screen and (min-width: 601px) {
    .navbar {
        top: 0;
        width: 5rem;
        height: 100vh;
    }
}

/* Mouse only stuff */
@media (hover: hover) and (pointer: fine) {
    .navbar:hover {
        width: 16rem;
    }

    .navbar:hover .link-text {
        display: block;
    }
  }